@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
      url("/public/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}

body {
  background: #f0f0f0 !important;
}